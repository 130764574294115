@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  font-family: "Inter";
  font-optical-sizing: auto;
  font-style: normal;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f9f9f9 !important;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: gray !important;
}

*::-moz-scrollbar-button,
::-webkit-scrollbar-button {
  width: 0px;
}
